@charset "UTF-8";
.mb0 {
  margin-bottom: 0px;
}

.mt0 {
  margin-top: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mt25 {
  margin-top: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mt35 {
  margin-top: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mt45 {
  margin-top: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt50 {
  margin-top: 50px;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz19 {
  font-size: 19px;
}

.fz20 {
  font-size: 20px;
}

.fz21 {
  font-size: 21px;
}

.fz22 {
  font-size: 22px;
}

.fz23 {
  font-size: 23px;
}

.fz24 {
  font-size: 24px;
}

.fz25 {
  font-size: 25px;
}

.fz26 {
  font-size: 26px;
}

.fz27 {
  font-size: 27px;
}

.fz28 {
  font-size: 28px;
}

.fz29 {
  font-size: 29px;
}

.fz30 {
  font-size: 30px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.cl:after {
  display: block;
  content: '';
  clear: both;
}

.pc_block {
  display: block;
}

@media only screen and (max-width: 768px) {
  .pc_block {
    display: none !important;
  }
}

.pc_inline {
  display: inline;
}

@media only screen and (max-width: 768px) {
  .pc_inline {
    display: none !important;
  }
}

.sp_block {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .sp_block {
    display: block !important;
  }
}

.sp_inline {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sp_inline {
    display: inline !important;
  }
}

.cur_month {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  margin-top: 20px;
  color: #3c3c3c;
}

.monthly-prev-next {
  color: #3c3c3c;
  font-size: 16px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .monthly-prev-next {
    font-size: 11.7px;
    font-size: 2.8125vw;
  }
}

.monthly-prev-next .monthly-prev .orange_tri {
  margin-right: 10px;
}

.monthly-prev-next .monthly-next .orange_tri {
  margin-left: 10px;
}

.monthly-prev-next .orange_tri {
  color: #ff3f12;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
}

.monthly-calendar table, .monthly-calendar table th, .monthly-calendar table td {
  border-collapse: collapse;
  border: 1px solid #c9caca;
}

.monthly-calendar table th, .monthly-calendar table td {
  width: 14.285%;
}

.monthly-calendar table th {
  padding: 7px 0;
  color: #fff !important;
  font-weight: normal;
  background: #898989;
}

.monthly-calendar table td {
  padding: 15px 0;
}

.monthly-calendar table .calendar-mark {
  font-weight: bold;
  font-size: 35px;
  color: #9fa0a0;
  line-height: 1;
  vertical-align: middle;
  margin-top: 5px;
}

.monthly-calendar table .calendar-daylink {
  font-weight: bold;
  font-size: 35px;
  color: #9fa0a0;
  line-height: 1;
  vertical-align: middle;
  margin-top: 5px;
}

.monthly-calendar table .calendar-daylink:hover {
  color: #ff3f12;
  transition: .3s;
}

.date_info {
  text-align: right;
  padding-right: 2%;
  margin-top: 10px;
}

.day-calendar {
  margin-top: 20px;
}

.day-calendar h3 {
  display: none;
}

.day-calendar .reserve_date {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 5px;
}

.day-calendar .day_cal {
  width: 100%;
}

.day-calendar .day_cal, .day-calendar .day_cal th, .day-calendar .day_cal td {
  border-collapse: collapse;
  border: 1px solid #c9caca;
  border-radius: 0;
}

.day-calendar .day_cal .header_tr th {
  background: #898989;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 0;
}

.day-calendar .day_cal .timetable .day-left {
  background: #fff;
  font-size: 18px;
  color: #3c3c3c;
  padding: 15px 0;
  letter-spacing: 5px;
}

@media only screen and (max-width: 768px) {
  .day-calendar .day_cal .timetable .day-left {
    font-size: 13px;
    font-size: 3.125vw;
  }
}

.day-calendar .day_cal .timetable .day-right {
  background: #f7f8f8;
  padding: 10px 0;
}

.day-calendar .day_cal .timetable .day-right a {
  font-size: 30px;
  color: #9fa0a0;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .day-calendar .day_cal .timetable .day-right a {
    font-size: 19.5px;
    font-size: 4.6875vw;
  }
}

.day-calendar .day_cal .timetable .day-right a:hover {
  color: #ff3f12;
  transition: .3s;
}

#content .content-form legend {
  display: none;
}

#content .content-form fieldset {
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
}

#content .content-form table, #content .content-form table th, #content .content-form table td {
  border-collapse: separate;
  border: none;
}

#content .content-form table th {
  width: 40%;
  background: #fff3df;
  font-size: 16px;
  text-align: left;
  padding: 15px 0;
  padding-left: 20px;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  #content .content-form table th {
    font-size: 11.7px;
    font-size: 2.8125vw;
  }
}

#content .content-form table th label {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  #content .content-form table th label {
    font-size: 10.4px;
    font-size: 2.5vw;
  }
}

#content .content-form table input {
  border: 2px solid #d2d2d2;
}

#content .content-form table input[type="radio"],
#content .content-form table input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  cursor: pointer;
}

#content .content-form table button {
  cursor: pointer;
}

#content .content-form table td {
  padding: 10px 0;
  padding-left: 3%;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  #content .content-form table td {
    font-size: 11.7px;
    font-size: 2.8125vw;
  }
}

#content .content-form table .date_td {
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  #content .content-form table .date_td {
    font-size: 10.4px;
    font-size: 2.5vw;
  }
}

#content .content-form table .check_box {
  margin-right: 15px;
}

#content .content-form table textarea {
  border: 2px solid #d2d2d2;
  height: 100px;
}

#content .content-form .vertical label {
  display: block;
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  #content .content-form .vertical label {
    font-size: 11.7px;
    font-size: 2.8125vw;
  }
}

#content .content-form .vertical label input {
  margin-right: 10px;
  vertical-align: middle;
}

#content .content-form .agree_text_block {
  border: 1px solid #d2d2d2;
  height: 150px;
  padding: 15px;
  overflow-y: scroll;
}

.agree_check {
  text-align: center;
  margin: 15px auto;
}

.agree_check .agree_text {
  font-size: 18px;
  color: #ff3f12;
}

@media only screen and (max-width: 768px) {
  .agree_check .agree_text {
    font-size: 13px;
    font-size: 3.125vw;
  }
}

.agree_check input {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.submit_text {
  text-align: center;
  font-size: 20px;
  margin: 0 auto 10px;
}

@media only screen and (max-width: 768px) {
  .submit_text {
    font-size: 14.3px;
    font-size: 3.4375vw;
  }
}

.thankyou_block {
  margin-top: 30px;
  text-align: center;
}

.thankyou_block .thankyou_header {
  display: block;
  margin: 0 auto 30px;
}

.thankyou_block .thankyou_text {
  font-size: 16px;
  line-height: 2;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .content-form .required {
    font-size: 8.45px;
    font-size: 2.03125vw;
  }
}

.access_block {
  margin-top: 45px;
}

.access_block .gmap {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
  margin-bottom: 30px;
}

.access_block .gmap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.access_block .orange_bold {
  font-weight: bold;
  margin-bottom: 5px;
  color: #ff3f12;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .access_block .orange_bold {
    font-size: 10.4px;
    font-size: 2.5vw;
  }
}

.access_block address {
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .access_block address {
    font-size: 10.4px;
    font-size: 2.5vw;
  }
}

.low_img.seminar {
  background: url(../img/seminar_img.png) no-repeat top center;
}

.seminar_list {
  margin: -10px 0 20px;
  color: #3c3c3c;
}

.min_seminar_date {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 24px;
  font-feature-settings: "palt";
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .min_seminar_date {
    font-size: 16.25px;
    font-size: 3.90625vw;
  }
}

.seminar_flow_image {
  margin-bottom: 40px;
}

.btn_wrap {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .btn_wrap img {
    width: 75%;
    height: auto;
  }
}

.split_block {
  margin-bottom: 25px;
}

.split_block .left {
  width: 26.3%;
  height: auto;
}

.split_block .right {
  width: 70.4%;
}

.visit_seminar_block {
  margin-bottom: 30px;
}

.jisseki_block {
  box-sizing: border-box;
}

.jisseki_block .jisseki_header {
  text-align: center;
  color: #fff;
  background: #FF3F12;
  font-size: 18px;
  line-height: 1.3;
  padding: 7px 0;
}

@media only screen and (max-width: 768px) {
  .jisseki_block .jisseki_header {
    font-size: 15.6px;
    font-size: 3.75vw;
  }
}

.jisseki_block .jisseki_content {
  border: 1px solid #FF3F12;
  padding: 15px;
  margin-bottom: 30px;
}

.jisseki_block .jisseki_content table {
  width: 100%;
}

.jisseki_block .jisseki_content th {
  font-weight: bold;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .jisseki_block .jisseki_content td {
    padding-left: 3%;
  }
}

.recruit.low_img {
  background: url(../img/recruit/recruit_img.png) no-repeat top center;
}

.recruit.header_5._desc {
  padding-bottom: 5px;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .recruit.header_5._desc {
    text-align: center;
    padding: 5px;
  }
}

.recruit_table {
  width: 100%;
  margin-top: 20px;
}

.recruit_table, .recruit_table th, .recruit_table td {
  border-collapse: collapse;
  border: 1px solid #c9caca;
}

.recruit_table th {
  padding-left: 20px;
  vertical-align: middle;
  background: #f0f0f0;
  font-size: 15px;
  width: 27.6%;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .recruit_table th {
    width: 100%;
    display: block;
    text-align: center;
    padding: 10px 0;
    font-size: 15.6px;
    font-size: 3.75vw;
  }
}

.recruit_table td {
  width: auto;
  padding: 20px;
  padding-left: 30px;
  padding-right: 0;
  line-height: 2;
}

@media only screen and (max-width: 768px) {
  .recruit_table td {
    width: 100%;
    display: block;
    padding: 15px 4%;
    font-size: 13.65px;
    font-size: 3.28125vw;
  }
}

.flow_image {
  margin-bottom: 25px;
}

@media only screen and (max-width: 768px) {
  .recruit_p {
    font-size: 13.65px;
    font-size: 3.28125vw;
  }
}

.contact_text {
  font-size: 20px;
  color: #ff3f12;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .contact_text {
    font-size: 18.2px;
    font-size: 4.375vw;
    width: 90%;
    margin: 0 auto 15px;
  }
}

.btn_wrap.recruit {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .btn_wrap.recruit {
    flex-direction: column;
  }
  .btn_wrap.recruit .btn_p:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 769px) {
  li.sp_block {
    display: none !important;
  }
}
