@charset "utf-8";
// 共通項目の設定をインポート
@import "utility";

.cur_month {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  margin-top: 20px;
  color: #3c3c3c;
}

.monthly-prev-next {
  color: #3c3c3c;
  font-size: 16px;
  margin-bottom: 10px;
  @include bpm(mobile) {
    @include fz_vw(18);
  }
  .monthly-prev {
    .orange_tri {
      margin-right: 10px;
    }
  }
  .monthly-next {
    .orange_tri {
      margin-left: 10px;
    }
  }
  .orange_tri {
    color: #ff3f12;
    font-size: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

.monthly-calendar {
  table {
    &,th,td {
      border-collapse: collapse;
      border: 1px solid #c9caca;
    }
    th,td {
      width: 14.285%;
    }
    th {
      padding: 7px 0;
      color: #fff !important;
      font-weight: normal;
      background: #898989;
    }
    td {
      padding: 15px 0;
    }
    .calendar-mark {
      font-weight: bold;
      font-size: 35px;
      color: #9fa0a0;
      line-height: 1;
      vertical-align: middle;
      margin-top: 5px;
    }
    .calendar-daylink {
      font-weight: bold;
      font-size: 35px;
      color: #9fa0a0;
      line-height: 1;
      vertical-align: middle;
      margin-top: 5px;
      &:hover {
        color: #ff3f12;
        transition: .3s;
      }
    }
  }
}

.date_info {
  text-align: right;
  padding-right: 2%;
  margin-top: 10px;
}

.day-calendar {
  margin-top: 20px;
  h3 {
    display: none;
  }
  .reserve_date {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
  }
  .day_cal {
    width: 100%;
    &,th,td {
      border-collapse: collapse;
      border: 1px solid #c9caca;
      border-radius: 0;
    }
    .header_tr {
      th {
        background: #898989;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        padding: 10px 0;
      }
    }
    .timetable {
      .day-left {
        background: #fff;
        font-size: 18px;
        color: #3c3c3c;
        padding: 15px 0;
        letter-spacing: 5px;
        @include bpm(mobile) {
          @include fz_vw(20);
        }
      }
      .day-right {
        background: #f7f8f8;
        padding: 10px 0;
        a {
          font-size: 30px;
          color: #9fa0a0;
          font-weight: bold;
          @include bpm(mobile) {
            @include fz_vw(30);
          }
          &:hover {
            color: #ff3f12;
            transition: .3s;
          }
        }
      }
    }
  }
}

#content .content-form {
  legend {
    display: none;
  }
  fieldset {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 25px;
  }
  table {
    &,th,td {
      border-collapse: separate;
      border: none;
    }
    th {
      width: 40%;
      background: #fff3df;
      font-size: 16px;
      text-align: left;
      padding: 15px 0;
      padding-left: 20px;
      vertical-align: middle;
      @include bpm(mobile) {
        @include fz_vw(18);
      }
      label {
        font-size: 16px;
        @include bpm(mobile) {
          @include fz_vw(16);
        }
      }
    }
    input {
      border: 2px solid #d2d2d2;
    }
    input[type="radio"],
    input[type="checkbox"] {
      height: 20px;
      width: 20px;
      vertical-align: middle;
      cursor: pointer;
    }
    button {
      cursor: pointer;
    }
    td {
      padding: 10px 0;
      padding-left: 3%;
      font-size: 16px;
      @include bpm(mobile) {
        @include fz_vw(18);
      }
    }
    .date_td {
      font-size: 14px;
      @include bpm(mobile) {
        @include fz_vw(16);
      }
    }
    .check_box {
      margin-right: 15px;
    }
    textarea {
      border: 2px solid #d2d2d2;
      height: 100px;
    }
  }
  .vertical {
    label {
      display: block;
      margin-bottom: 5px;
      @include bpm(mobile) {
        @include fz_vw(18);
      }
      input {
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
  .agree_text_block {
    border: 1px solid #d2d2d2;
    height: 150px;
    padding: 15px;
    overflow-y: scroll;
  }
}
.agree_check {
  text-align: center;
  margin: 15px auto;
  .agree_text {
    font-size: 18px;
    color: #ff3f12;
    @include bpm(mobile) {
      @include fz_vw(20);
    }
  }
  input {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}
.submit_text {
  text-align: center;
  font-size: 20px;
  margin: 0 auto 10px;
  @include bpm(mobile) {
    @include fz_vw(22);
  }
}

.thankyou_block {
  margin-top: 30px;
  text-align: center;
  .thankyou_header {
    display: block;
    margin: 0 auto 30px;
  }
  .thankyou_text {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 15px;
  }
}

.content-form .required {
  @include bpm(mobile) {
    @include fz_vw(13);
  }
}

.access_block {
  margin-top: 45px;
  .gmap {
    position: relative;
    padding-bottom: 75%; // これが縦横比
    height: 0;
    overflow: hidden;
    margin-bottom: 30px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
  .orange_bold {
    font-weight: bold;
    margin-bottom: 5px;
    color: #ff3f12;
    font-size: 14px;
    @include bpm(mobile) {
      @include fz_vw(16);
    }
  }
  address {
    margin-bottom: 20px;
    display: block;
    font-size: 14px;
    @include bpm(mobile) {
      @include fz_vw(16);
    }
  }
}

.low_img.seminar {
	background: url(../img/seminar_img.png) no-repeat top center;
}

// セミナーページ
.seminar_list {
  margin: -10px 0 20px;
  color: #3c3c3c;
}
.min_seminar_date {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 24px;
  font-feature-settings: "palt";
  margin-bottom: 5px;
  @include bpm(mobile) {
    @include fz_vw(25);
  }
}
.seminar_flow_image {
  margin-bottom: 40px;
}
.btn_wrap {
  text-align: center;
  @include bpm(mobile) {
    img {
      width: 75%;
      height: auto;
    }
  }
}

.split_block {
  margin-bottom: 25px;
  .left {
    width: 26.3%;
    height: auto;
  }
  .right {
    width: 70.4%;
  }
}
.visit_seminar_block {
  margin-bottom: 30px;
}

.jisseki_block {
  box-sizing: border-box;
  .jisseki_header {
    text-align: center;
    color: #fff;
    background: #FF3F12;
    font-size: 18px;
    line-height: 1.3;
    padding: 7px 0;
    @include bpm(mobile) {
      @include fz_vw(24);
    }
  }
  .jisseki_content {
    border: 1px solid #FF3F12;
    padding: 15px;
    margin-bottom: 30px;
    table {
      width: 100%;
    }
    th {
      // width: 30%;
      font-weight: bold;
      text-align: left;
    }
    td {
      // width: 70%;
      // padding-left: 10%;
      @include bpm(mobile) {
        padding-left: 3%;
      }
    }
  }
}

.recruit.low_img {
  background: url(../img/recruit/recruit_img.png) no-repeat top center;
}
.recruit.header_5._desc {
  padding-bottom: 5px;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 20px;
  @include bpm(mobile) {
    text-align: center;
    padding: 5px;
  }
}

.recruit_table {
  width: 100%;
  margin-top: 20px;
  &,th,td {
    border-collapse: collapse;
    border: 1px solid #c9caca;
  }
  th {
    padding-left: 20px;
    vertical-align: middle;
    background: #f0f0f0;
    font-size: 15px;
    width: 27.6%;
    text-align: left;
    @include bpm(mobile) {
      width: 100%;
      display: block;
      text-align: center;
      padding: 10px 0;
      @include fz_vw(24);
    }
  }
  td {
    width: auto;
    padding: 20px;
    padding-left: 30px;
    padding-right: 0;
    line-height: 2;
    @include bpm(mobile) {
      width: 100%;
      display: block;
      padding: 15px 4%;
      @include fz_vw(21);
    }
  }
}

.flow_image {
  margin-bottom: 25px;
}
.recruit_p {
  @include bpm(mobile) {
    @include fz_vw(21);
  }
}
.contact_text {
  font-size: 20px;
  color: #ff3f12;
  text-align: center;
  margin-bottom: 20px;
  @include bpm(mobile) {
    @include fz_vw(28);
    width: 90%;
    margin: 0 auto 15px;
  }
}

.btn_wrap.recruit {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  @include bpm(mobile) {
    flex-direction: column;
    .btn_p:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}
li.sp_block {
  @include bpm(desktop) {
    display: none !important;
  }
}