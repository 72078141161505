@mixin bpm($point)
{
  @if $point == desktop{
    @media only screen and (min-width:769px)
    {
      @content;
    }
  }
  @else if $point == mobile{
    @media only screen and (max-width:768px)
    {
      @content;
    }
  }
}

// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
// AIデータのフォントサイズをそのまま入れると割と良い感じになるようにしてます
@function get_vw($size, $viewport:640){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:10){
  font-size: $font_size * .65px;
  font-size: get_vw($font_size);
}

@mixin flexbox
{
  display:-webkit-box; // old
  display:-moz-box; // old
  display:-ms-flexbox; // ie
  display:-webkit-flex; // new
  display:flex; // new
}

@for $i from 0 through 10 {
  .mb#{$i * 5} {
    margin-bottom:#{$i * 5}px;
  }
  .mt#{$i * 5} {
    margin-top:#{$i * 5}px;
  }
}
@for $i from 10 through 30 {
  .fz#{$i} {
    font-size: #{$i}px;
  }
}

.left { float: left; }
.right { float: right; }

.cl:after {
  display: block;
  content: '';
  clear: both;
}

.pc_block {
  display: block;
  @include bpm(mobile) {
    display: none !important;
  }
}
.pc_inline {
  display: inline;
  @include bpm(mobile) {
    display: none !important;
  }
}

.sp_block {
  display: none !important;
  @include bpm(mobile) {
    display: block !important;
  }
}
.sp_inline {
  display: none;
  @include bpm(mobile) {
    display: inline !important;
  }
}